import React, { useState, useEffect } from "react"
import axios from "axios"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
  Tooltip,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import ReactPaginate from "react-paginate"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import * as XLSX from "xlsx" // Importar XLSX para exportar a Excel
import "./Facturas.css"
import { format, differenceInDays, isValid } from "date-fns"

const formatCurrency = value => {
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  }).format(value)
}

const Facturas = props => {
  document.title = "Consulta Comprobantes | LS-DA - Softlogy"

  const navigate = useNavigate()
  const [facturas, setFacturas] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalDocs, setTotalDocs] = useState(0)
  const [loading, setLoading] = useState(false)
  const [documentos, setDocumentos] = useState([])
  const [empresas, setEmpresas] = useState([])
  const [formapago, setFormapago] = useState([])
  const [mediopago, setMediopago] = useState([])
  const [nitreceptor, setNitreceptor] = useState("")
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState("")
  const [detalleModal, setDetalleModal] = useState(false)
  const [detalleContent, setDetalleContent] = useState(null)
  const [cufe, setCufe] = useState("")
  const [nitEmisor, setnitEmisor] = useState("")
  const [noFactura, setnoFactura] = useState("")

  const toggleModal = () => setModal(!modal)
  const toggleDetalleModal = () => setDetalleModal(!detalleModal)

  const handleDianClick = cufe => {
    const url = `https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=${cufe}`
    setModalContent(url)
    toggleModal()
  }

  const handleDetalleClick = async CufeCude => {
    //console.log("CufeCude", CufeCude)
    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/recepcion/recupera`,
        {
          params: {
            CufeCude: CufeCude,
          },
        },
      )
      setDetalleContent(response.data.facturas[0])
      //console.log("DEtalleC", detalleContent)
      toggleDetalleModal()
    } catch (error) {
      console.error("Error fetching detalle data:", error)
    }
  }

  const fetchFacturas = async (page = 1) => {
    setLoading(true)
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    //console.log("Fetching facturas for page: ", page)
    /*console.log("API call parameters: ", {
      NitReceptor: nitreceptor,
      fechaInicio: fechaInicio ? fechaInicio.toISOString().split("T")[0] : "",
      fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",

      page: page,
    })*/

    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/recepcion/recupera`,
        {
          params: {
            NitReceptor: nitreceptor,
            fechaInicio: fechaInicio
              ? fechaInicio.toISOString().split("T")[0]
              : "",
            fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",

            page: page,
            CufeCude: cufe,
            NitEmisor: nitEmisor,
            NumFactura: noFactura,
          },
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        },
      )
      setFacturas(response.data.facturas)
      setTotalPages(response.data.totalPages)
      setTotalDocs(response.data.totalDocs)
      setCurrentPage(page - 1) // Adjusted to be zero-based
    } catch (error) {
      console.error("Error fetching facturas data:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchFormaPago = async () => {
    try {
      const response = await axios.get("/listas/FormasPago-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setFormapago(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchMedioPago = async () => {
    try {
      const response = await axios.get("/listas/MediosPago-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setMediopago(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    //console.log("currentUser", currentUser)

    if (!currentUser || !currentUser.empresas) {
      //console.log("User data not fully available yet, skipping fetch.")
      return
    }
    try {
      setEmpresas(currentUser.empresas)
    } catch (error) {
      console.error("Error fetching empresas data:", error)
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Comprobantes", link: "#" },
        { title: "Consulta Comprobantes", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchTipoDocumento()
    fetchEmpresas()
    fetchFormaPago()
    fetchMedioPago()
  }, [props])

  const handlePageClick = data => {
    const selectedPage = data.selected + 1
    //console.log("Selected page: ", selectedPage)
    setCurrentPage(selectedPage)
    fetchFacturas(selectedPage)
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetchFacturas(1)
  }

  const handleExportToExcel = async () => {
    let allFacturas
    setLoading(true)

    const apiUrl = process.env.REACT_APP_API_BASE_URL

    try {
      const response = await axios.get(
        `${apiUrl}/recepcion/hub-latam/recepcion/recupera`,
        {
          params: {
            NitReceptor: nitreceptor,
            fechaInicio: fechaInicio
              ? fechaInicio.toISOString().split("T")[0]
              : "",
            fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
            CufeCude: cufe,
            NitEmisor: nitEmisor,
            all: true, // Solicita todos los documentos sin paginación
          },
        },
      )
      allFacturas = response.data.facturas
    } catch (error) {
      console.error("Error fetching all facturas data:", error)
      allFacturas = []
    } finally {
      setLoading(false)
    }

    // Definir los códigos de eventos a incluir
    const eventCodes = [
      "030",
      "032",
      "033",
      "031",
      "034",
      "036",
      "037",
      "045",
      "046",
      "047",
      "049",
    ]

    const formattedFacturas = allFacturas.map(factura => {
      const fecha = new Date(factura.Fecha)
      const vencimientoFactura = new Date(factura.VencimientoFactura)
      const eventos = {}
      let fechaEndoso = "No"
      let endosadoA = "No"

      // Inicializa todos los eventos a "No"
      eventCodes.forEach(code => {
        eventos[`Evento ${code}`] = "No"
      })

      // Añadir eventos a las columnas adicionales
      if (factura.Eventos && factura.Eventos.Evento) {
        factura.Eventos.Evento.forEach(event => {
          if (eventCodes.includes(event.ResponseCode)) {
            eventos[`Evento ${event.ResponseCode}`] = "Sí"
            if (event.ResponseCode === "037") {
              fechaEndoso = format(new Date(event.EffectiveDate), "yyyy-MM-dd")
              endosadoA = event.RegistrationName
            }
          }
        })
      }

      return {
        NitEmisor: factura.NitEmisor,
        NombreEmisor: factura.NombreEmisor,
        NitReceptor: factura.NitReceptor,
        NombreReceptor: factura.NombreReceptor,
        TipoDocumento:
          factura.TipoDocumento === "01" ? "Factura" : "Nota de Credito",
        NumFactura: factura.NumFactura,
        Fecha: format(fecha, "yyyy-MM-dd"),
        VencimientoFactura: format(vencimientoFactura, "yyyy-MM-dd"),
        TotalFactura: formatCurrency(factura.TotalFactura.$numberDecimal),
        FormaPago: getFormapagoNombre(factura.FormaPagoFactura),
        MedioPago: getMediopagoNombre(factura.MedioPagoFactura),
        CufeCude: factura.CufeCude,
        ...eventos,
        FechaEndoso: fechaEndoso,
        EndosadoA: endosadoA,
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(formattedFacturas)

    // Agregar encabezados y aplicar estilos básicos
    const header = [
      "Nit Emisor",
      "Nombre Emisor",
      "Nit Receptor",
      "Nombre Receptor",
      "Tipo Documento",
      "Número Factura",
      "Fecha",
      "Vencimiento Factura",
      "Total Factura",
      "Forma de Pago",
      "Medio de Pago",
      "CUFE/CUDE",
      ...eventCodes.map(code => `Evento ${code}`),
      "Fecha Endoso",
      "Endosado a",
    ]
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" })

    // Aplicar estilos a las celdas
    const range = XLSX.utils.decode_range(worksheet["!ref"])
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1" // Primera fila (encabezado)
      if (!worksheet[address]) continue
      worksheet[address].s = {
        font: {
          bold: true,
          sz: 12,
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        fill: {
          fgColor: { rgb: "FFFFAA00" },
        },
      }
    }

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Facturas")

    // Guardar el nombre del archivo con fecha y hora
    const fileName = `facturas_${new Date().toISOString().replace(/[-:.]/g, "_")}.xlsx`
    XLSX.writeFile(workbook, fileName)

    setLoading(false) // Detener el spinner
  }

  const getDocumentoNombre = code => {
    const doc = documentos.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const getFormapagoNombre = code => {
    const doc = formapago.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const getMediopagoNombre = code => {
    const doc = mediopago.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa.NumeroIdentificacion,
    label: empresa.idempresa.Nombre,
  }))

  const handleNitreceptorChange = selectedOption => {
    setNitreceptor(selectedOption ? selectedOption.value : "")
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1050,
    }),
  }

  const eventDescriptions = {
    "Evento 030": "Aval",
    "Evento 032": "Documento validado por la DIAN",
    "Evento 033": "Documento rechazado por la DIAN",
    "Evento 031": "Aval",
    "Evento 034": "Endoso en propiedad",
    "Evento 036":
      "Inscripción en el RADIAN de la factura electrónica de venta como título valor que circula en el territorio nacional",
    "Evento 037": "Endoso en propiedad",
    "Evento 045": "Pago de la factura electrónica de venta como título valor",
    "Evento 046": "Informe para el pago",
    "Evento 047": "Endoso con efectos de cesión ordinaria",
    "Evento 049": "Transferencia de los derechos económicos",
  }

  const [tooltipOpen, setTooltipOpen] = useState({})

  const toggleTooltip = id => {
    setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const eventColumns = [
    "Evento 030",
    "Evento 032",
    "Evento 033",
    "Evento 031",
    "Evento 034",
    "Evento 036",
    "Evento 037",
    "Evento 045",
    "Evento 046",
    "Evento 047",
    "Evento 049",
  ]

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Facturas</CardTitle>
                <div>
                  <Button color="success" onClick={handleExportToExcel} inline>
                    Exportar a Excel
                  </Button>
                  <Button color="danger" onClick={() => navigate("/portal")}>
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Visualice y gestione las facturas recibidas.
              </p>
              <hr />
              <Form onSubmit={handleSubmit} inline>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="nitreceptor">NIT Receptor</Label>
                      <Select
                        id="nitreceptor"
                        value={empresaOptions.find(
                          option => option.value === nitreceptor,
                        )}
                        onChange={handleNitreceptorChange}
                        options={empresaOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una empresa"
                        isClearable
                        required
                        styles={customStyles} // Aplica los estilos personalizados
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaInicio">Fecha Inicio</Label>
                      <DatePicker
                        selected={fechaInicio}
                        onChange={date => setFechaInicio(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaFin">Fecha Fin</Label>
                      <DatePicker
                        selected={fechaFin}
                        onChange={date => setFechaFin(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label for="cufe">CUFE</Label>
                      <Input
                        type="text"
                        id="cufe"
                        value={cufe}
                        onChange={e => setCufe(e.target.value)}
                        placeholder="Ingrese el CUFE"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="nitEmisor">Nit Emisor</Label>
                      <Input
                        type="text"
                        id="nitEmisor"
                        value={nitEmisor}
                        onChange={e => setnitEmisor(e.target.value)}
                        placeholder="Ingrese el Nit Emisor"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="nofactura"># Facturar</Label>
                      <Input
                        type="text"
                        id="noFactura"
                        value={noFactura}
                        onChange={e => setnoFactura(e.target.value)}
                        placeholder="Ingrese el # de Factura"
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    md={1}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button type="submit" color="primary">
                      Consultar
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />

              {loading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                  <p>Generando archivo, por favor espere...</p>
                </div>
              ) : (
                <div>
                  <div>Total registros: {totalDocs}</div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead
                          style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "white",
                            zIndex: 1,
                          }}
                        >
                          <Tr>
                            <Th>Acción</Th>

                            <Th>Nombre Emisor</Th>
                            <Th>NIT Emisor</Th>
                            <Th>Tipo Comprobante</Th>
                            <Th>Nombre Receptor</Th>
                            <Th>NIT Receptor</Th>
                            <Th>No Factura</Th>
                            <Th>Total</Th>
                            <Th>Forma de Pago</Th>
                            <Th>Medio de Pago</Th>
                            <Th>Fecha</Th>
                            <Th>Fecha Vencimiento</Th>
                            <Th>Días de Credito</Th>
                            <Th>CUFE</Th>
                            {eventColumns.map(eventColumn => {
                              const tooltipId = `tooltip-${eventColumn.replace(/\s+/g, "-")}` // Crear un ID de tooltip válido
                              return (
                                <Th key={eventColumn}>
                                  <div id={tooltipId}>{eventColumn}</div>
                                  <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpen[tooltipId]}
                                    target={tooltipId}
                                    toggle={() => toggleTooltip(tooltipId)}
                                  >
                                    {eventDescriptions[eventColumn]}
                                  </Tooltip>
                                </Th>
                              )
                            })}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {facturas.map(factura => {
                            const fecha = new Date(factura.Fecha)
                            const vencimientoFactura = new Date(
                              factura.VencimientoFactura,
                            )
                            const diasPorVencer = differenceInDays(
                              vencimientoFactura,
                              fecha,
                            )

                            if (
                              !isValid(fecha) ||
                              !isValid(vencimientoFactura)
                            ) {
                              console.error("Invalid date in factura:", factura)
                              return (
                                <Tr key={factura.id}>
                                  <Td>Fecha inválida</Td>
                                  <Td>Fecha inválida</Td>
                                  <Td>N/A</Td>
                                </Tr>
                              )
                            }

                            // Inicializa el objeto de eventos con los códigos de respuesta específicos
                            const eventos = {
                              "030": false,
                              "032": false,
                              "033": false,
                              "031": false,
                              "034": false,
                              "036": false,
                              "037": false,
                              "045": false,
                              "046": false,
                              "047": false,
                              "049": false,
                            }

                            if (factura.Eventos && factura.Eventos.Evento) {
                              factura.Eventos.Evento.forEach(event => {
                                //console.log(
                                //     `Procesando evento: ${event.ResponseCode}`,
                                //  )
                                if (event.ResponseCode === "030")
                                  eventos["030"] = true
                                if (event.ResponseCode === "032")
                                  eventos["032"] = true
                                if (event.ResponseCode === "033")
                                  eventos["033"] = true
                                if (event.ResponseCode === "031")
                                  eventos["031"] = true
                                if (event.ResponseCode === "034")
                                  eventos["034"] = true
                                if (event.ResponseCode === "036")
                                  eventos["036"] = true
                                if (event.ResponseCode === "037")
                                  eventos["037"] = true
                                if (event.ResponseCode === "045")
                                  eventos["045"] = true
                                if (event.ResponseCode === "046")
                                  eventos["046"] = true
                                if (event.ResponseCode === "047")
                                  eventos["047"] = true
                                if (event.ResponseCode === "049")
                                  eventos["049"] = true
                              })
                            }

                            //console.log(
                            //    `Eventos procesados para la factura ${factura.NumFactura}:`,
                            //   eventos,
                            // )

                            return (
                              <Tr key={factura.id}>
                                <Td>
                                  <div className="d-flex justify-content-around">
                                    <Button
                                      id="btDIAN"
                                      type="button"
                                      outline
                                      color="info"
                                      className="waves-effect waves-light"
                                      onClick={() =>
                                        handleDianClick(factura.CufeCude)
                                      }
                                    >
                                      <i className="fas fa-building"></i>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="btDIAN"
                                      >
                                        DIAN
                                      </UncontrolledTooltip>
                                    </Button>
                                    <Button
                                      id="btDetalle"
                                      type="button"
                                      outline
                                      color="primary"
                                      className="waves-effect waves-light"
                                      onClick={() =>
                                        handleDetalleClick(factura.CufeCude)
                                      }
                                    >
                                      <i className="fas fa-eye"></i>
                                    </Button>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="btDetalle"
                                    >
                                      Detalle
                                    </UncontrolledTooltip>
                                  </div>
                                </Td>

                                <Td>{factura.NombreEmisor}</Td>
                                <Td>{factura.NitEmisor}</Td>
                                <Td>
                                  {getDocumentoNombre(factura.TipoDocumento)}
                                </Td>
                                <Td>{factura.NombreReceptor}</Td>
                                <Td>{factura.NitReceptor}</Td>
                                <Td>{factura.NumFactura}</Td>
                                <Td>
                                  {formatCurrency(
                                    factura.TotalFactura.$numberDecimal,
                                  )}
                                </Td>
                                <Td>
                                  {getFormapagoNombre(factura.FormaPagoFactura)}
                                </Td>
                                <Td>
                                  {getMediopagoNombre(factura.MedioPagoFactura)}
                                </Td>
                                <Td>{format(fecha, "yyyy-MM-dd")}</Td>
                                <Td>
                                  {format(vencimientoFactura, "yyyy-MM-dd")}
                                </Td>
                                <Td>{diasPorVencer}</Td>
                                <Td>{factura.CufeCude}</Td>
                                {eventColumns.map(eventColumn => {
                                  const eventCode = eventColumn.split(" ")[1] // Extrae el código del evento
                                  const event = factura.Eventos.Evento.find(
                                    e => e.ResponseCode === eventCode,
                                  ) // Busca el evento correspondiente

                                  //console.log(
                                  //   `Evaluando columna: ${eventColumn} para evento: ${eventCode} - Presente: ${eventos[eventCode]}`,
                                  // )
                                  return (
                                    <Td key={eventColumn}>
                                      {eventos[eventCode] ? (
                                        <>
                                          <span>✅</span>
                                          <br />
                                          <small>
                                            {format(
                                              new Date(event.EffectiveDate),
                                              "yyyy-MM-dd",
                                            )}
                                          </small>

                                          <br />
                                          <small>
                                            {event.RegistrationName}
                                          </small>
                                        </>
                                      ) : (
                                        <span>❌</span>
                                      )}
                                    </Td>
                                  )
                                })}
                              </Tr>
                            )
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>Total registros: {totalDocs}</div>
                    <ReactPaginate
                      previousLabel={"← Anterior"}
                      nextLabel={"Siguiente →"}
                      pageCount={totalPages}
                      forcePage={currentPage}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                    />
                  </div>
                </div>
              )}
              <Modal
                isOpen={modal}
                toggle={toggleModal}
                size="lg"
                style={{ maxWidth: "80%" }}
              >
                <ModalHeader toggle={toggleModal}>DIAN Document</ModalHeader>
                <ModalBody>
                  <iframe
                    src={modalContent}
                    style={{ width: "100%", height: "600px" }}
                    title="DIAN Document"
                  ></iframe>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModal}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={detalleModal}
                toggle={toggleDetalleModal}
                size="lg"
              >
                <ModalHeader toggle={toggleDetalleModal}>
                  Detalle del Comprobante
                </ModalHeader>
                <ModalBody>
                  {detalleContent && (
                    <div>
                      <p>
                        <strong>Emisor:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Nombre:</strong> {detalleContent.NombreEmisor}
                        </li>
                        <li>
                          <strong>NIT:</strong> {detalleContent.NitEmisor}
                        </li>
                      </ul>

                      <p>
                        <strong>Adquiriente:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Nombre:</strong>{" "}
                          {detalleContent.NombreReceptor}
                        </li>
                        <li>
                          <strong>NIT:</strong> {detalleContent.NitReceptor}
                        </li>
                      </ul>

                      <p>
                        <strong>Factura:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Número:</strong> {detalleContent.NumFactura}
                        </li>
                        <li>
                          <strong>Fecha:</strong>{" "}
                          {format(new Date(detalleContent.Fecha), "yyyy-MM-dd")}
                        </li>
                        <li>
                          <strong>Vencimiento:</strong>{" "}
                          {format(
                            new Date(detalleContent.VencimientoFactura),
                            "yyyy-MM-dd",
                          )}
                        </li>
                        <li>
                          <strong>Total:</strong>{" "}
                          {formatCurrency(
                            detalleContent.TotalFactura.$numberDecimal,
                          )}
                        </li>
                      </ul>

                      <p>
                        <strong>Archivos:</strong>
                      </p>
                      <ul>
                        <li>
                          <a
                            href={detalleContent.Invoice}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            XML Invoice
                          </a>
                        </li>
                        <li>
                          <a
                            href={detalleContent.Eventos.archivo}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            XML Eventos
                          </a>
                        </li>
                      </ul>

                      <p>
                        <strong>Eventos:</strong>
                      </p>
                      <ul>
                        {detalleContent.Eventos.Evento.map(evento => (
                          <li key={evento._id}>
                            <strong>Code:</strong> {evento.ResponseCode},{" "}
                            <strong>Fecha:</strong>{" "}
                            {format(
                              new Date(evento.EffectiveDate),
                              "yyyy-MM-dd",
                            )}
                            , <strong>Nombre:</strong> {evento.RegistrationName}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleDetalleModal}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(Facturas)
