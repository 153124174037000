import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  CardTitle,
  Card,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"

const CargarArchivo = props => {
  document.title = "Carga DIAN | LS-DA - Softlogy"
  const navigate = useNavigate()

  const [file, setFile] = useState(null)
  const [idEmpresa, setIdEmpresa] = useState("")
  const [empresas, setEmpresas] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [uploads, setUploads] = useState([]) // Para almacenar los detalles de los archivos cargados

  const handleFileChange = e => {
    setFile(e.target.files[0])
  }

  const handleIdEmpresaChange = selectedOption => {
    setIdEmpresa(selectedOption ? selectedOption.value : "")
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!file || !idEmpresa) {
      setErrorMessage("Por favor, seleccione un archivo y una empresa.")
      return
    }

    const formData = new FormData()
    formData.append("archivo", file)
    formData.append("idEmpresa", idEmpresa)

    const uploadStartTime = new Date().toLocaleString() // Hora de inicio de la carga

    setLoading(true)
    setSuccessMessage("")
    setErrorMessage("")

    try {
      const response = await axios.post(
        "https://timbrador.ls-da.com/recepcion/hub-latam/archivos/DIAN",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )

      // Agregar detalles del archivo a la tabla
      setUploads(prevUploads => [
        ...prevUploads,
        {
          id: response.data.archivoId, // Asume que el backend devuelve un ID del archivo
          nombre: file.name,
          horaInicio: uploadStartTime,
          horaFin: "En proceso...",
          status: "En proceso",
        },
      ])

      // Monitorear el estado del archivo periódicamente
      if (response.data.archivoId) {
        monitorArchivoEstado(response.data.archivoId)
      } else {
        setErrorMessage("No se pudo obtener el ID del archivo cargado.")
      }
      setSuccessMessage("Archivo cargado exitosamente y procesado.")
    } catch (error) {
      setErrorMessage("Error al cargar el archivo: " + error.message)
    } finally {
      setLoading(false)
    }
  }

  const monitorArchivoEstado = async archivoId => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(
          `https://timbrador.ls-da.com/recepcion/hub-latam/archivos/estado/${archivoId}`,
        )
        if (
          response.data.status === "Procesado" ||
          response.data.status === "Error"
        ) {
          // Asume que hay un campo 'status' en el modelo
          setUploads(prevUploads =>
            prevUploads.map(upload =>
              upload.id === archivoId
                ? {
                    ...upload,
                    horaFin: new Date().toLocaleString(),
                    status: response.data.status,
                  }
                : upload,
            ),
          )
          clearInterval(intervalId) // Detener la verificación
        }
      } catch (error) {
        console.error("Error al obtener el estado del archivo:", error)
      }
    }, 5000) // Verificar cada 5 segundos
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Comprobantes", link: "#" },
        { title: "Carga Archivo DIAN", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)

      // Obtener las empresas del usuario autenticado
      setEmpresas(currentUser.empresas || [])

      // Obtener el historial de archivos cargados
      fetchHistorialArchivos()
    }
  }, [props])

  const fetchHistorialArchivos = async () => {
    try {
      const response = await axios.get(
        "https://timbrador.ls-da.com/recepcion/hub-latam/archivos/historial",
      )
      setUploads(response.data)
    } catch (error) {
      console.error("Error al obtener el historial de archivos:", error)
    }
  }

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa._id,
    label: empresa.idempresa.Nombre,
  }))

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Carga de Archivos DIAN</CardTitle>
                <div>
                  <Button color="danger" onClick={() => navigate("/portal")}>
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Suba sus archivos DIAN en formato Excel para procesarlos y
                registrarlos en el sistema.
              </p>
              <hr />

              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="idEmpresa">Seleccione Empresa</Label>
                  <Select
                    id="idEmpresa"
                    value={empresaOptions.find(
                      option => option.value === idEmpresa,
                    )}
                    onChange={handleIdEmpresaChange}
                    options={empresaOptions}
                    classNamePrefix="select2-selection"
                    placeholder="Seleccione una empresa"
                    isClearable
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="archivo">Archivo</Label>
                  <Input
                    type="file"
                    id="archivo"
                    onChange={handleFileChange}
                    required
                  />
                </FormGroup>
                <Button type="submit" color="primary" disabled={loading}>
                  {loading ? <Spinner size="sm" /> : "Cargar Archivo"}
                </Button>
              </Form>
              {successMessage && (
                <Alert color="success" className="mt-3">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert color="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}

              <Table className="mt-4" bordered>
                <thead>
                  <tr>
                    <th>Nombre del Archivo</th>
                    <th>Hora de Inicio</th>
                    <th>Status</th>
                    <th>Hora de Finalización</th>
                  </tr>
                </thead>
                <tbody>
                  {uploads.map(upload => (
                    <tr key={upload._id}>
                      <td>{upload.nombre}</td>
                      <td>{new Date(upload.horaInicio).toLocaleString()}</td>
                      <td>{upload.status}</td>
                      <td>
                        {upload.horaFin
                          ? new Date(upload.horaFin).toLocaleString()
                          : "En proceso..."}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(CargarArchivo)
