import React, { useEffect, useState } from "react"
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist"
import jsQR from "jsqr"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  CardTitle,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { format, differenceInDays, isValid } from "date-fns"

// Configura el worker de PDF.js para usar la versión local
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry"

GlobalWorkerOptions.workerSrc = pdfWorker

const formatCurrency = value => {
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  }).format(value)
}

const CargarPDF = props => {
  document.title = "Carga PDF | LS-DA - Softlogy"

  const [files, setFiles] = useState([])
  const [idEmpresa, setIdEmpresa] = useState("")
  const [empresas, setEmpresas] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [cufeValues, setCufeValues] = useState([])
  const [failedFiles, setFailedFiles] = useState([])
  const [mismatchedFiles, setMismatchedFiles] = useState([])
  const [processingFiles, setProcessingFiles] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [documentos, setDocumentos] = useState([])
  const [formapago, setFormapago] = useState([])
  const [mediopago, setMediopago] = useState([])

  const [facturas, setFacturas] = useState([])

  const navigate = useNavigate()

  const handleFileChange = e => {
    setFiles(e.target.files)
  }

  const handleIdEmpresaChange = selectedOption => {
    setIdEmpresa(selectedOption ? selectedOption.value : "")
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState("")
  const [detalleModal, setDetalleModal] = useState(false)
  const [detalleContent, setDetalleContent] = useState(null)

  const toggleModal = () => setModal(!modal)
  const toggleDetalleModal = () => setDetalleModal(!detalleModal)

  const handleDianClick = cufe => {
    const url = `https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=${cufe}`
    setModalContent(url)
    toggleModal()
  }

  const handleDetalleClick = async CufeCude => {
    //console.log("CufeCude", CufeCude)
    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/recepcion/recupera`,
        {
          params: {
            CufeCude: CufeCude,
          },
        },
      )
      setDetalleContent(response.data.facturas[0])
      //console.log("DEtalleC", detalleContent)
      toggleDetalleModal()
    } catch (error) {
      console.error("Error fetching detalle data:", error)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (files.length === 0 || !idEmpresa) {
      setErrorMessage("Por favor, seleccione uno o más archivos y una empresa.")
      return
    }

    setLoading(true)
    setSuccessMessage("")
    setErrorMessage("")
    setCufeValues([])
    setFailedFiles([])
    setMismatchedFiles([])
    setProcessingFiles(true)

    const selectedEmpresa = empresas.find(
      empresa => empresa.idempresa._id === idEmpresa,
    )

    try {
      const newCufeValues = []
      const newFailedFiles = []
      const newMismatchedFiles = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileReader = new FileReader()
        fileReader.onload = async function () {
          const typedarray = new Uint8Array(this.result)
          //console.log("Typed array created from file:", typedarray)

          const pdf = await getDocument({ data: typedarray }).promise
          //console.log("PDF loaded:", pdf)

          let foundCufe = false

          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum)
            //console.log(`Processing page ${pageNum} of ${pdf.numPages}`)

            const viewport = page.getViewport({ scale: 6.0 })
            const canvas = document.createElement("canvas")
            const context = canvas.getContext("2d")
            canvas.width = viewport.width
            canvas.height = viewport.height

            await page.render({
              canvasContext: context,
              viewport: viewport,
            }).promise

            //console.log("Canvas rendered for page:", pageNum)
            const imageData = context.getImageData(
              0,
              0,
              canvas.width,
              canvas.height,
            )
            //console.log("ImageData created:", imageData)
            const code = jsQR(imageData.data, imageData.width, imageData.height)

            if (code) {
              //console.log(`QR code found on page ${pageNum}:`, code.data)
              const documentKey = code.data.match(
                /https:\/\/catalogo-vpfe\.dian\.gov\.co\/document\/searchqr\?documentkey=([a-zA-Z0-9]+)/,
              )

              const nitMatch = code.data.match(/DocAdq[:=]\s?(\d+)/i)

              const extractedNit = nitMatch ? nitMatch[1] : null
              if (documentKey) {
                if (
                  selectedEmpresa &&
                  selectedEmpresa.idempresa.NumeroIdentificacion ===
                    extractedNit
                ) {
                  newCufeValues.push({
                    fileName: file.name,
                    cufe: documentKey[1],
                    nit: extractedNit,
                  })
                  setCufeValues(prevValues => [
                    ...prevValues,
                    {
                      fileName: file.name,
                      cufe: documentKey[1],
                      nit: extractedNit,
                    },
                  ])
                  await fetch(
                    `https://timbrador.ls-da.com/recepcion/hub-latam/archivos/valPDF?nit=${selectedEmpresa.idempresa.NumeroIdentificacion}&cufe=${documentKey[1]}`,
                  )

                  await fetch(
                    `https://timbrador.ls-da.com/recepcion/hub-latam/archivos/obtieneEventos?&cufe=${documentKey[1]}`,
                  )
                } else {
                  newMismatchedFiles.push({
                    fileName: file.name,
                    cufe: documentKey[1],
                    nit: extractedNit,
                  })
                  setMismatchedFiles(prevValues => [
                    ...prevValues,
                    {
                      fileName: file.name,
                      cufe: documentKey[1],
                      nit: extractedNit,
                    },
                  ])
                }
                foundCufe = true
                break
              }
            } else {
              //console.log(`No QR code found on page ${pageNum}`)
            }
          }

          if (!foundCufe) {
            newFailedFiles.push(file.name)
            setFailedFiles(prevValues => [...prevValues, file.name])
          }

          if (i === files.length - 1) {
            if (newFailedFiles.length > 0) {
              setErrorMessage(
                `Algunos archivos no pudieron ser procesados. Archivos fallidos: ${newFailedFiles.length}`,
              )
              setTimeout(() => {
                setErrorMessage("")
              }, 5000)
            }

            if (newCufeValues.length > 0) {
              setSuccessMessage(
                `Archivos procesados con éxito. Archivos exitosos: ${newCufeValues.length}`,
              )
              setTimeout(() => {
                setSuccessMessage("")
              }, 5000)

              // Llamar a la función fetchComprobantes con los CUFES encontrados
              await fetchComprobantes(
                newCufeValues,
                selectedEmpresa.idempresa.NumeroIdentificacion,
              )
            }

            setProcessingFiles(false)
          }
        }

        fileReader.readAsArrayBuffer(file)
      }
    } catch (error) {
      console.error("Error al procesar el archivo PDF:", error)
      setErrorMessage("Error al procesar el archivo PDF: " + error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchFormaPago = async () => {
    try {
      const response = await axios.get("/listas/FormasPago-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setFormapago(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchMedioPago = async () => {
    try {
      const response = await axios.get("/listas/MediosPago-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setMediopago(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Comprobantes", link: "#" },
        { title: "Carga Archivo PDF", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)

      setEmpresas(currentUser.empresas || [])
    }
    fetchTipoDocumento()
    fetchFormaPago()
    fetchMedioPago()
  }, [props])

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa._id,
    label: empresa.idempresa.Nombre,
  }))

  const fetchComprobantes = async (cufeValues, nitReceptor) => {
    //console.log("ESTE NIT: ", nitReceptor)

    try {
      const response = await fetch(
        "https://timbrador.ls-da.com/recepcion/hub-latam/recepcion/recupera",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            NitReceptor: nitReceptor,
            CufeCude: cufeValues.map(cv => cv.cufe),
          }),
        },
      )

      const data = await response.json()

      /*console.log(
        "ESTE CUFE: ",
        cufeValues.map(cv => cv.cufe),
      )*/
      //console.log("ESTE res: ", data)

      setFacturas(data.facturas)
    } catch (error) {
      console.error("Error fetching comprobantes:", error)
    }
  }

  const getDocumentoNombre = code => {
    const doc = documentos.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const getFormapagoNombre = code => {
    const doc = formapago.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const getMediopagoNombre = code => {
    const doc = mediopago.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const eventDescriptions = {
    "Evento 030": "Aval",
    "Evento 032": "Documento validado por la DIAN",
    "Evento 033": "Documento rechazado por la DIAN",
    "Evento 031": "Aval",
    "Evento 034": "Endoso en propiedad",
    "Evento 036":
      "Inscripción en el RADIAN de la factura electrónica de venta como título valor que circula en el territorio nacional",
    "Evento 037": "Endoso en propiedad",
    "Evento 045": "Pago de la factura electrónica de venta como título valor",
    "Evento 046": "Informe para el pago",
    "Evento 047": "Endoso con efectos de cesión ordinaria",
    "Evento 049": "Transferencia de los derechos económicos",
  }

  const [tooltipOpen, setTooltipOpen] = useState({})

  const toggleTooltip = id => {
    setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const eventColumns = [
    "Evento 030",
    "Evento 032",
    "Evento 033",
    "Evento 031",
    "Evento 034",
    "Evento 036",
    "Evento 037",
    "Evento 045",
    "Evento 046",
    "Evento 047",
    "Evento 049",
  ]

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Carga de Archivos PDF</CardTitle>
                <div>
                  <Button color="danger" onClick={() => navigate("/portal")}>
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Suba sus archivos PDF para extraer y verificar el valor de CUFE.
                Una vez cargados, el sistema procesará los archivos y mostrará
                el resultado automáticamente.
              </p>
              <hr />

              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="idEmpresa">Seleccione Empresa</Label>
                  <Select
                    id="idEmpresa"
                    value={empresaOptions.find(
                      option => option.value === idEmpresa,
                    )}
                    onChange={handleIdEmpresaChange}
                    options={empresaOptions}
                    classNamePrefix="select2-selection"
                    placeholder="Seleccione una empresa"
                    isClearable
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="archivo">Archivos PDF</Label>
                  <Input
                    type="file"
                    id="archivo"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    multiple
                    required
                  />
                </FormGroup>
                <Button type="submit" color="primary" disabled={loading}>
                  {loading ? <Spinner size="sm" /> : "Cargar Archivos"}
                </Button>
              </Form>
              {processingFiles && (
                <div className="text-center mt-3">
                  <Spinner size="sm" />
                  <p>Procesando archivos...</p>
                </div>
              )}
              {successMessage && (
                <Alert color="success" className="mt-3">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert color="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}
              <Nav tabs className="mt-4">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Archivos Procesados
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Archivos no Procesados
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3")
                    }}
                  >
                    Archivos no Coinciden con el NIT
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="mt-4">
                <TabPane tabId="1">
                  {cufeValues.length > 0 && (
                    <div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead
                              style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                                zIndex: 1,
                              }}
                            >
                              <Tr>
                                <Th>Acción</Th>

                                <Th>Nombre Emisor</Th>
                                <Th>NIT Emisor</Th>
                                <Th>Tipo Comprobante</Th>
                                <Th>Nombre Receptor</Th>
                                <Th>NIT Receptor</Th>
                                <Th>No Factura</Th>
                                <Th>Total</Th>
                                <Th>Forma de Pago</Th>
                                <Th>Medio de Pago</Th>
                                <Th>Fecha</Th>
                                <Th>Fecha Vencimiento</Th>
                                <Th>Días de Credito</Th>
                                <Th>CUFE</Th>
                                {eventColumns.map(eventColumn => {
                                  const tooltipId = `tooltip-${eventColumn.replace(/\s+/g, "-")}` // Crear un ID de tooltip válido
                                  return (
                                    <Th key={eventColumn}>
                                      <div id={tooltipId}>{eventColumn}</div>
                                      <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen[tooltipId]}
                                        target={tooltipId}
                                        toggle={() => toggleTooltip(tooltipId)}
                                      >
                                        {eventDescriptions[eventColumn]}
                                      </Tooltip>
                                    </Th>
                                  )
                                })}
                              </Tr>
                            </Thead>
                            <Tbody>
                              {facturas.map(factura => {
                                const fecha = new Date(factura.Fecha)
                                const vencimientoFactura = new Date(
                                  factura.VencimientoFactura,
                                )
                                const diasPorVencer = differenceInDays(
                                  vencimientoFactura,
                                  fecha,
                                )

                                if (
                                  !isValid(fecha) ||
                                  !isValid(vencimientoFactura)
                                ) {
                                  console.error(
                                    "Invalid date in factura:",
                                    factura,
                                  )
                                  return (
                                    <Tr key={factura.id}>
                                      <Td>Fecha inválida</Td>
                                      <Td>Fecha inválida</Td>
                                      <Td>N/A</Td>
                                    </Tr>
                                  )
                                }

                                // Inicializa el objeto de eventos con los códigos de respuesta específicos
                                const eventos = {
                                  "030": false,
                                  "032": false,
                                  "033": false,
                                  "031": false,
                                  "034": false,
                                  "036": false,
                                  "037": false,
                                  "045": false,
                                  "046": false,
                                  "047": false,
                                  "049": false,
                                }

                                if (factura.Eventos && factura.Eventos.Evento) {
                                  factura.Eventos.Evento.forEach(event => {
                                    //console.log(
                                    //    `Procesando evento: ${event.ResponseCode}`,
                                    // )
                                    if (event.ResponseCode === "030")
                                      eventos["030"] = true
                                    if (event.ResponseCode === "032")
                                      eventos["032"] = true
                                    if (event.ResponseCode === "033")
                                      eventos["033"] = true
                                    if (event.ResponseCode === "031")
                                      eventos["031"] = true
                                    if (event.ResponseCode === "034")
                                      eventos["034"] = true
                                    if (event.ResponseCode === "036")
                                      eventos["036"] = true
                                    if (event.ResponseCode === "037")
                                      eventos["037"] = true
                                    if (event.ResponseCode === "045")
                                      eventos["045"] = true
                                    if (event.ResponseCode === "046")
                                      eventos["046"] = true
                                    if (event.ResponseCode === "047")
                                      eventos["047"] = true
                                    if (event.ResponseCode === "049")
                                      eventos["049"] = true
                                  })
                                }

                                //console.log(
                                //      `Eventos procesados para la factura ${factura.NumFactura}:`,
                                //    eventos,
                                // )

                                return (
                                  <Tr key={factura.id}>
                                    <Td>
                                      <div className="d-flex justify-content-around">
                                        <Button
                                          id="btDIAN"
                                          type="button"
                                          outline
                                          color="info"
                                          className="waves-effect waves-light"
                                          onClick={() =>
                                            handleDianClick(factura.CufeCude)
                                          }
                                        >
                                          <i className="fas fa-building"></i>
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="btDIAN"
                                          >
                                            DIAN
                                          </UncontrolledTooltip>
                                        </Button>
                                        <Button
                                          id="btDetalle"
                                          type="button"
                                          outline
                                          color="primary"
                                          className="waves-effect waves-light"
                                          onClick={() =>
                                            handleDetalleClick(factura.CufeCude)
                                          }
                                        >
                                          <i className="fas fa-eye"></i>
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="btDetalle"
                                        >
                                          Detalle
                                        </UncontrolledTooltip>
                                      </div>
                                    </Td>

                                    <Td>{factura.NombreEmisor}</Td>
                                    <Td>{factura.NitEmisor}</Td>
                                    <Td>
                                      {getDocumentoNombre(
                                        factura.TipoDocumento,
                                      )}
                                    </Td>
                                    <Td>{factura.NombreReceptor}</Td>
                                    <Td>{factura.NitReceptor}</Td>
                                    <Td>{factura.NumFactura}</Td>
                                    <Td>
                                      {formatCurrency(
                                        factura.TotalFactura.$numberDecimal,
                                      )}
                                    </Td>
                                    <Td>
                                      {getFormapagoNombre(
                                        factura.FormaPagoFactura,
                                      )}
                                    </Td>
                                    <Td>
                                      {getMediopagoNombre(
                                        factura.MedioPagoFactura,
                                      )}
                                    </Td>
                                    <Td>{format(fecha, "yyyy-MM-dd")}</Td>
                                    <Td>
                                      {format(vencimientoFactura, "yyyy-MM-dd")}
                                    </Td>
                                    <Td>{diasPorVencer}</Td>
                                    <Td>{factura.CufeCude}</Td>
                                    {eventColumns.map(eventColumn => {
                                      const eventCode =
                                        eventColumn.split(" ")[1] // Extrae el código del evento
                                      const event = factura.Eventos.Evento.find(
                                        e => e.ResponseCode === eventCode,
                                      ) // Busca el evento correspondiente

                                      //console.log(
                                      //   `Evaluando columna: ${eventColumn} para evento: ${eventCode} - Presente: ${eventos[eventCode]}`,
                                      // )
                                      return (
                                        <Td key={eventColumn}>
                                          {eventos[eventCode] ? (
                                            <>
                                              <span>✅</span>
                                              <br />
                                              <small>
                                                {format(
                                                  new Date(event.EffectiveDate),
                                                  "yyyy-MM-dd",
                                                )}
                                              </small>

                                              <br />
                                              <small>
                                                {event.RegistrationName}
                                              </small>
                                            </>
                                          ) : (
                                            <span>❌</span>
                                          )}
                                        </Td>
                                      )
                                    })}
                                  </Tr>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {failedFiles.length > 0 && (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Archivos no procesados</th>
                        </tr>
                      </thead>
                      <tbody>
                        {failedFiles.map((fileName, index) => (
                          <tr key={index}>
                            <td>{fileName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </TabPane>
                <TabPane tabId="3">
                  {mismatchedFiles.length > 0 && (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Archivos no coinciden con el NIT seleccionado</th>
                          <th>Valor de CUFE</th>
                          <th>NIT Destinatario</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mismatchedFiles.map((file, index) => (
                          <tr key={index}>
                            <td>{file.fileName}</td>
                            <td>{file.cufe}</td>
                            <td>{file.nit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={toggleModal}
                    size="lg"
                    style={{ maxWidth: "80%" }}
                  >
                    <ModalHeader toggle={toggleModal}>
                      DIAN Document
                    </ModalHeader>
                    <ModalBody>
                      <iframe
                        src={modalContent}
                        style={{ width: "100%", height: "600px" }}
                        title="DIAN Document"
                      ></iframe>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggleModal}>
                        Cerrar
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={detalleModal}
                    toggle={toggleDetalleModal}
                    size="lg"
                  >
                    <ModalHeader toggle={toggleDetalleModal}>
                      Detalle del Comprobante
                    </ModalHeader>
                    <ModalBody>
                      {detalleContent && (
                        <div>
                          <p>
                            <strong>Emisor:</strong>
                          </p>
                          <ul>
                            <li>
                              <strong>Nombre:</strong>{" "}
                              {detalleContent.NombreEmisor}
                            </li>
                            <li>
                              <strong>NIT:</strong> {detalleContent.NitEmisor}
                            </li>
                          </ul>

                          <p>
                            <strong>Adquiriente:</strong>
                          </p>
                          <ul>
                            <li>
                              <strong>Nombre:</strong>{" "}
                              {detalleContent.NombreReceptor}
                            </li>
                            <li>
                              <strong>NIT:</strong> {detalleContent.NitReceptor}
                            </li>
                          </ul>

                          <p>
                            <strong>Factura:</strong>
                          </p>
                          <ul>
                            <li>
                              <strong>Número:</strong>{" "}
                              {detalleContent.NumFactura}
                            </li>
                            <li>
                              <strong>Fecha:</strong>{" "}
                              {format(
                                new Date(detalleContent.Fecha),
                                "yyyy-MM-dd",
                              )}
                            </li>
                            <li>
                              <strong>Vencimiento:</strong>{" "}
                              {format(
                                new Date(detalleContent.VencimientoFactura),
                                "yyyy-MM-dd",
                              )}
                            </li>
                            <li>
                              <strong>Total:</strong>{" "}
                              {formatCurrency(
                                detalleContent.TotalFactura.$numberDecimal,
                              )}
                            </li>
                          </ul>

                          <p>
                            <strong>Archivos:</strong>
                          </p>
                          <ul>
                            <li>
                              <a
                                href={detalleContent.Invoice}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                XML Invoice
                              </a>
                            </li>
                            <li>
                              <a
                                href={detalleContent.Eventos.archivo}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                XML Eventos
                              </a>
                            </li>
                          </ul>

                          <p>
                            <strong>Eventos:</strong>
                          </p>
                          <ul>
                            {detalleContent.Eventos.Evento.map(evento => (
                              <li key={evento._id}>
                                <strong>Code:</strong> {evento.ResponseCode},{" "}
                                <strong>Fecha:</strong>{" "}
                                {format(
                                  new Date(evento.EffectiveDate),
                                  "yyyy-MM-dd",
                                )}
                                , <strong>Nombre:</strong>{" "}
                                {evento.RegistrationName}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggleDetalleModal}>
                        Cerrar
                      </Button>
                    </ModalFooter>
                  </Modal>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(CargarPDF)
