import React, { useState, useEffect } from "react"
import axios from "axios"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./Facturas.css"
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"

const ConsultaEventosDian = props => {
  document.title = "Eventos x DIA | LS-DA - Softlogy"

  const navigate = useNavigate()

  const [empresas, setEmpresas] = useState([])
  const [archivos, setArchivos] = useState([])
  const [nitemisor, setNitemisor] = useState("")
  const [fechaInicio, setFechaInicio] = useState(null)
  const [loading, setLoading] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [noEventos, setNoEventos] = useState(false)
  const [filtroEndosada, setFiltroEndosada] = useState("Todas")

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    //console.log("currentUser", currentUser)

    if (!currentUser || !currentUser.empresas) {
      //console.log("User data not fully available yet, skipping fetch.")
      return
    }
    try {
      setEmpresas(currentUser.empresas)
    } catch (error) {
      console.error("Error fetching empresas data:", error)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setGenerating(true)
    setLoading(true)
    setNoEventos(false)

    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.empresas) {
      console.error("User data not fully available yet.")
      setGenerating(false)
      setLoading(false)
      return
    }

    const selectedEmpresa = currentUser.empresas.find(
      empresa => empresa.idempresa.NumeroIdentificacion === nitemisor,
    )

    if (
      !selectedEmpresa ||
      !selectedEmpresa.idempresa.DatosDIAN ||
      !selectedEmpresa.idempresa.DatosDIAN.PassCert
    ) {
      console.error("Empresa seleccionada no encontrada o no tiene PassCert.")
      setGenerating(false)
      setLoading(false)
      return
    }

    const passCert = selectedEmpresa.idempresa.DatosDIAN.PassCert
    const fechaFormatted = fechaInicio
      ? fechaInicio.toISOString().split("T")[0].replace(/-/g, "")
      : ""

    /*console.log("Generating Excel with filters: ", {
      nitemisor: nitemisor,
      fechaInicio: fechaFormatted,
      passCert: passCert,
    })*/

    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/archivos/obtieneEventosDIA`,
        {
          params: {
            Nit: nitemisor,
            fecha: fechaFormatted,
          },
        },
      )

      const eventos = response.data.Eventos
      if (eventos.length === 0) {
        setNoEventos(true)
      } else {
        const archivosConEndoso = eventos.map(archivo => {
          const eventoEndoso = archivo.Eventos.Evento.find(
            event => event.ResponseCode === "037",
          )
          return {
            ...archivo,
            EndosadaA: eventoEndoso ? eventoEndoso.RegistrationName : null,
          }
        })
        setArchivos(archivosConEndoso)
      }
    } catch (error) {
      console.error("Error generating Excel:", error)
    } finally {
      setGenerating(false)
      setLoading(false)
    }
  }

  const exportToExcel = () => {
    const data = archivos.map(archivo => {
      return {
        ...archivo,
        Eventos: archivo.Eventos.Evento.map(
          event =>
            `Evento: ${event.ResponseCode}, Fecha: ${event.EffectiveDate}, Entidad: ${event.RegistrationName}`,
        ).join("\n"),
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Eventos")

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })

    const blobData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    })
    saveAs(
      blobData,
      `Eventos_${nitemisor}_${fechaInicio.toISOString().split("T")[0]}.xlsx`,
    )
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Comprobantes", link: "#" },
        { title: "Eventos x DIA", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchEmpresas()
  }, [props])

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa.NumeroIdentificacion,
    label: empresa.idempresa.Nombre,
  }))

  const handleNitemisorChange = selectedOption => {
    setNitemisor(selectedOption ? selectedOption.value : "")
  }

  const handleFiltroEndosadaChange = selectedOption => {
    setFiltroEndosada(selectedOption ? selectedOption.value : "Todas")
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1050,
    }),
  }

  const filtroOptions = [
    { value: "Todas", label: "Todas" },
    { value: "ConEndoso", label: "Con Endoso" },
  ]

  const archivosFiltrados = archivos.filter(archivo => {
    if (filtroEndosada === "ConEndoso") {
      return archivo.EndosadaA
    }
    return true
  })

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Generar Reporte</CardTitle>
                <Button color="danger" onClick={() => navigate("/portal")}>
                  Salir
                </Button>
              </div>
              <p className="card-title-desc">
                Utilice los filtros para generar reporte de eventos realizados.
              </p>
              <hr />
              <Form onSubmit={handleSubmit} inline>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="nitemisor">Empresa</Label>
                      <Select
                        id="nitemisor"
                        value={empresaOptions.find(
                          option => option.value === nitemisor,
                        )}
                        onChange={handleNitemisorChange}
                        options={empresaOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una empresa"
                        isClearable
                        styles={customStyles}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="filtroEndosada">Filtro</Label>
                      <Select
                        id="filtroEndosada"
                        value={filtroOptions.find(
                          option => option.value === filtroEndosada,
                        )}
                        onChange={handleFiltroEndosadaChange}
                        options={filtroOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione un filtro"
                        isClearable
                        styles={customStyles}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaInicio">Fecha</Label>
                      <DatePicker
                        selected={fechaInicio}
                        onChange={date => setFechaInicio(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col
                    md={1}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button type="submit" color="primary" disabled={generating}>
                      {generating ? "Generando..." : "Generar"}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div>
                  {noEventos ? (
                    <p>No hay eventos para el día seleccionado.</p>
                  ) : (
                    <div>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          Total archivos generados: {archivosFiltrados.length}
                        </div>
                        <Button color="success" onClick={exportToExcel}>
                          Exportar a Excel
                        </Button>
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0 fixed-header"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="archivos-generados"
                            className="table table-striped table-bordered"
                          >
                            <Thead className="sticky-header">
                              <Tr>
                                <Th>Endosada a</Th>
                                <Th>Nit Emisor</Th>
                                <Th>Nombre Emisor</Th>
                                <Th># Factura</Th>
                                <Th>Valor Factura</Th>
                                <Th>Fecha Factura</Th>
                                <Th>Vencimiento Factura</Th>
                                <Th>Eventos</Th>
                                <Th>Cufe</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {archivosFiltrados.map((archivo, index) => (
                                <Tr key={index}>
                                  <Td>
                                    {archivo.EndosadaA
                                      ? `Endosada a: ${archivo.EndosadaA}`
                                      : ""}
                                  </Td>
                                  <Td>{archivo.NitEmisor}</Td>
                                  <Td>{archivo.NombreEmisor}</Td>
                                  <Td>{archivo.NumFactura}</Td>
                                  <Td>{archivo.TotalFactura.$numberDecimal}</Td>
                                  <Td>{archivo.FechaFactura}</Td>
                                  <Td>{archivo.VencimientoFactura}</Td>
                                  <Td>
                                    {archivo.Eventos.Evento.map(event => (
                                      <div key={event._id}>
                                        <strong>Evento:</strong>{" "}
                                        {event.ResponseCode}{" "}
                                        <strong>Fecha:</strong>{" "}
                                        {event.EffectiveDate}{" "}
                                        <strong>Entidad:</strong>{" "}
                                        {event.RegistrationName}
                                      </div>
                                    ))}
                                  </Td>
                                  <Td>{archivo.Cufe}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(
  ConsultaEventosDian,
)
